<template>
  <th-page-wrapper v-loading="loading">
    <service-category-empty v-if="!loading && isEmpty" />
    <th-datatable
      v-show="!loading && !isEmpty"
      do-route
      do-route-filters
      show-search-filter
      no-meta-check
      sortable
      resource="productGroups"
      :resource-query="{ query: { is_service_category: true, deleted: false } }"
      :show-operations="false"
      route-base="/reservations/service_category"
      :headers="headers"
      :buttons="computedButtons"
      :permissions="permissions"
      @loading-error="handleLoadingError"
    />
  </th-page-wrapper>
</template>

<script>
import ServiceCategoryEmpty from './empty'

export default {
  name: 'ServiceCategoriesAll',
  components: {
    ServiceCategoryEmpty
  },
  data() {
    return {
      isEmpty: true,
      loading: false,
      permissions: {
        create: {
          scopes: ['reservations:service_categories:create']
        },
        update: {
          scopes: ['reservations:service_categories:update']
        },
        delete: {
          scopes: ['reservations:service_categories:delete']
        }
      },
      headers: [
        {
          field: 'name',
          label: this.$t('common.headers.name.title'),
          minWidth: 120,
          truncate: true,
          fallback: '-'
        },
        {
          field: 'description',
          label: this.$t('common.headers.description.title'),
          minWidth: 180,
          truncate: true,
          fallback: '-'
        }
      ],
      buttons: [
        {
          type: 'create',
          scopes: ['reservations:service_categories:create']
        }
      ]
    }
  },
  computed: {
    computedButtons() {
      return this.buttons.filter((b) =>
        b.scopes ? this.$checkPermissions({ scopes: b.scopes }) : true
      )
    }
  },
  created() {
    this.fetch()
  },
  methods: {
    async fetch() {
      try {
        this.loading = true
        const { productGroups } = await this.$resourceFetch({
          resource: 'productGroups',
          query: { is_service_category: true, deleted: false }
        })
        this.isEmpty = Array.isArray(productGroups) && !productGroups.length
      } catch (err) {
        this.handleLoadingError(err)
      } finally {
        this.loading = false
      }
    },
    handleLoadingError(err) {
      this.$logException(err, {
        trackError: false,
        message: this.$t('common.error.action.read.multiple', {
          resources: this.$t('nav.main.items.reservations.service_category')
        })
      })
    }
  }
}
</script>
